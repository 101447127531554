.card {
    background-color:#f1f2f6;
    width: 500px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin-top: 75px;
    position: relative;
    padding: 15px;
}
.questionNumber{
    position: absolute;
    top: -40px;
    left: 20px;
    background-color: #2E3443;
    color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    height: 40px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.questionNumber1{
    position: absolute;
    top: -43px;
    left: 120px;
    color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    height: 40px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.questionNumber h1{
    font-size: 16px;
}

.question {
    padding: 10px;
    border: none;
    border-radius: 10px;
    width: 90%;
    align-self: center;
    resize: none;
    height: 100px;
    border: 0.1px solid #dcdcdc;
    font-size: 16px;
}

textarea:focus {
    border: none;
    outline: none;
}

.answerContainer  {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.answerContainer textarea {
    width: 70%;
    height: 70px;
    margin-top: 20px;
    margin-left: 20px;
    border-radius: 10px;
    border: none;
    resize: none;
    padding: 10px;
    border: 0.1px solid #dcdcdc;
    font-size: 16px;
}

.answerContainer input[type=radio] {
    -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  outline: none;
  border: 0.1px solid #dcdcdc;
  background-color: #fff;
}

.answerContainer input[type="radio"]:checked {
    background-color: #35dc76;
  }

.header-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    background-color:#f1f2f6;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    position: relative;
    margin-top: 50px;
}


.header-Container textarea {
    width: 85%;
    height: 80px;
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    resize: none;
    padding: 10px;
    border: 0.1px solid #dcdcdc;
    font-size: 16px;
    align-self: center;
}

.edit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 70px);
    overflow: scroll;
    padding-top: 15px;
}

.edit-container button {
    border: none;
    margin: 0px 20px;
    padding: 10px;
    border-radius: 25px;
    background-color: #2E3443;
    color: #fff;
    cursor: pointer;
    width: 90px;
    margin-top: 20px;
    margin-bottom: 20px;
}