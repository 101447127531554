.detailView{
    height: calc(100vh - 70px);
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}

.detailView1{
    height: calc(100vh - 70px);
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.detail-parameters {
    background-color: #f1f2f6;
    padding: 30px;
    margin-top: 20px;
    border-radius: 10px;
    width: 500px;
    font-size: 16px;
}

.view-design {
    padding: 10px;
    border: 0.1px solid #dcdcdc;
    margin-top: 10px;
    width: 80%;
    align-self: center;
    border-radius: 10px;
}

.color-answer {
    background-color:#2ed573;
    color: white;
}

.editButton {
    border: none;
    margin: 0px 20px;
    padding: 10px;
    border-radius: 25px;
    background-color: #2E3443;
    color: #fff;
    cursor: pointer;
    width: 90px;
    margin-top: 20px;
    margin-bottom: 20px;
    position: fixed;
    left: 90%;
    top: 50%;
    transform: translate(-50%, 0);
    font-size: 16px;
    font-weight: bold;
}

.editButton:hover{
    border: 1px solid #2E3443;
    background-color: white;
    color: black;
}
