header {
    background: rgb(223, 228, 234);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-container {
    width: 150px;
    height: 150px;
}

.logo-container img {
    width: 100%;
    height: 60%;
}

.login-container {
    background-color:#f1f2f6;
    width: 500px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    position: relative;
    padding: 15px;
}

.login-container .input-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.login-container .input-container div {
    align-self: center;
    font-size: 20px;
    color: #2E3443;
}

.login-container input {
    align-self: center;
    width: 300px;
    padding: 18px 25px;
    border-radius: 20px;
    margin-top: 10px;
    border: 0.1px solid #dcdcdc;
}

.login-container button {
    align-self: center;
    margin-top: 30px;
    width: 300px;
    height: 45px;
    border-radius: 20px;
    border: none;
    background-color: #2ed573;
    color: #ffffff;
    font-size: 20px;
    border: 1px solid #2ed573;
    margin-bottom: 10px;
    font-weight: bold;
    letter-spacing: 0.2px;
}

.login-container button:hover {
    background-color: #2ddb76;
    border: none;
}


input:focus, button:focus {
    outline: none;
  }