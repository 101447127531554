@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
header {
    background: rgb(223, 228, 234);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-container {
    width: 150px;
    height: 150px;
}

.logo-container img {
    width: 100%;
    height: 60%;
}

.login-container {
    background-color:#f1f2f6;
    width: 500px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    position: relative;
    padding: 15px;
}

.login-container .input-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.login-container .input-container div {
    align-self: center;
    font-size: 20px;
    color: #2E3443;
}

.login-container input {
    align-self: center;
    width: 300px;
    padding: 18px 25px;
    border-radius: 20px;
    margin-top: 10px;
    border: 0.1px solid #dcdcdc;
}

.login-container button {
    align-self: center;
    margin-top: 30px;
    width: 300px;
    height: 45px;
    border-radius: 20px;
    border: none;
    background-color: #2ed573;
    color: #ffffff;
    font-size: 20px;
    border: 1px solid #2ed573;
    margin-bottom: 10px;
    font-weight: bold;
    letter-spacing: 0.2px;
}

.login-container button:hover {
    background-color: #2ddb76;
    border: none;
}


input:focus, button:focus {
    outline: none;
  }
.lds-roller {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
}
.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@-webkit-keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    color: #2E3443;
  }

  .navigation {
    height: 70px;
    background-color: white;
    padding:0px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: -2px 0px 8px 0px rgba(0,0,0,0.21);
  }

  .navigation img {
    width: 130px;
    height:70px;
}
  
  td, th {
    border: 1px solid #dcdcdc;
    text-align: left;
    padding: 8px;
    text-align: center;
  }

  tr:hover{
    background-color:rgba(0,0,0,0.05);
  }

  td:nth-child(6):hover, td:nth-child(7):hover, td:nth-child(8):hover, td:nth-child(9):hover, td:nth-child(10):hover{
    background-color: #dcdcdc;
  }


  .navGrid {
    display: grid;
    grid-template-columns: 15% 85%;
    grid-gap: 10;
    gap: 10;
    background: rgb(223, 228, 234);
  }

  .subnav {
    background-color:#2E3443;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:10px 10px;
    height: calc(100vh - 70px);
    color: #fff;
  }

  .subnav button {
    border: none;
    background-color: transparent;
    color: #dcdcdc;
    margin-top: 5px;
    font-size: 18px;
    text-align: left;
  }

  .subnav button:hover {
    color: #fff;
  }

  .backgroundColor{   
    height: 100vh;
    background-color: #ffffff;
  }

  .loading {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pagination{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
  }

  .pagination button {
    border: none;
    margin: 0px 20px;
    padding: 10px;
    border-radius: 25px;
    background-color: #2E3443;
    color: #fff;
    cursor: pointer;
    width: 90px;
  }

  .pagination button:hover {
    transform: scale(1.1);
    }

  .navigation a {
    background-color: #2E3443;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 45px;
    border-radius: 25px;
    font-size: 14px;
    width: 120px;
    margin-top: 13px;
  }

  .navigation a:hover {
    background-color: #fff;
    color: #2E3443;
    border: 0.5px solid #2E3443;
  }

  .cardTable{
    background-color: #fff;
    padding: 15px;
    margin: 15px;
    border-radius: 10px;
  }

  .popUp {
    position: absolute;
    border: none;
    background-color: #dcdcdc;
    top: 20px;
    width: 90px;
    border-radius: 20px;
    padding:10px;
    cursor:none;
    caret-color: transparent;
    margin-left: 50%;
    transform: translateX(50%);
  }

  .view:hover{
   background-color: #dcdcdc;
  }

  .search{
    align-self: center;
  }

  .search input {
    border-radius: 20px;
    padding: 10px;
    border: 1px solid #2E3443
  }

  .search button {
    background-color: #2E3443;
    color: #fff;
    padding : 10px 20px;
    border-radius: 20px;
    border: none;
    margin-left: 10px;
  }



.create-quiz-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.delete {
    margin-left: 20px;
    border: none;
    background-color: red;
    height: 30px;
    width: 30px;
    border-radius: 100px;
    color: #fff;
}

.add-option {
    width: 150px;
    align-self: center;
    margin-top: 20px;
    border: none;
    background-color: #2E3443;
    color: #fff;
    height: 50px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: bold;
}

.addQuestion {
    width: 150px;
    align-self: center;
    margin-top: 20px;
    border: none;
    background-color: #fff;
    color: #2E3443;
    height: 50px;
    border-radius: 30px;
    align-self: center;
    font-size: 16px;
    font-weight: bold;
}

.result {
    padding: 10px;
    border: none;
    border-radius: 10px;
    width: 80%;
    align-self: center;
    resize: none;
    height: 60px;
    border: 0.1px solid #dcdcdc;
    font-size: 16px;
}

.submit {
    width: 150px;
    align-self: center;
    margin-top: 20px;
    border: none;
    background-color: #2E3443;
    color: #fff;
    height: 50px;
    border-radius: 30px;
    align-self: center;
    font-size: 16px;
    font-weight: bold;
}

.fileContainer {
    overflow: hidden;
    position: relative;
    width: 150px;
    align-self: center;
    border: none;
    background-color: #2E3443;
    color: #fff;
    border-radius: 30px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.fileContainer [type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

select {
    -webkit-appearance: none;
    position: relative;
}

.dropdown {
    position: absolute;
    top: 35px;
    right: 100px;
    z-index: 1000;
}
.card {
    background-color:#f1f2f6;
    width: 500px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin-top: 75px;
    position: relative;
    padding: 15px;
}
.questionNumber{
    position: absolute;
    top: -40px;
    left: 20px;
    background-color: #2E3443;
    color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    height: 40px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.questionNumber1{
    position: absolute;
    top: -43px;
    left: 120px;
    color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    height: 40px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.questionNumber h1{
    font-size: 16px;
}

.question {
    padding: 10px;
    border: none;
    border-radius: 10px;
    width: 90%;
    align-self: center;
    resize: none;
    height: 100px;
    border: 0.1px solid #dcdcdc;
    font-size: 16px;
}

textarea:focus {
    border: none;
    outline: none;
}

.answerContainer  {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.answerContainer textarea {
    width: 70%;
    height: 70px;
    margin-top: 20px;
    margin-left: 20px;
    border-radius: 10px;
    border: none;
    resize: none;
    padding: 10px;
    border: 0.1px solid #dcdcdc;
    font-size: 16px;
}

.answerContainer input[type=radio] {
    -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  outline: none;
  border: 0.1px solid #dcdcdc;
  background-color: #fff;
}

.answerContainer input[type="radio"]:checked {
    background-color: #35dc76;
  }

.header-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    background-color:#f1f2f6;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    position: relative;
    margin-top: 50px;
}


.header-Container textarea {
    width: 85%;
    height: 80px;
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    resize: none;
    padding: 10px;
    border: 0.1px solid #dcdcdc;
    font-size: 16px;
    align-self: center;
}

.edit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 70px);
    overflow: scroll;
    padding-top: 15px;
}

.edit-container button {
    border: none;
    margin: 0px 20px;
    padding: 10px;
    border-radius: 25px;
    background-color: #2E3443;
    color: #fff;
    cursor: pointer;
    width: 90px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.detailView{
    height: calc(100vh - 70px);
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}

.detailView1{
    height: calc(100vh - 70px);
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.detail-parameters {
    background-color: #f1f2f6;
    padding: 30px;
    margin-top: 20px;
    border-radius: 10px;
    width: 500px;
    font-size: 16px;
}

.view-design {
    padding: 10px;
    border: 0.1px solid #dcdcdc;
    margin-top: 10px;
    width: 80%;
    align-self: center;
    border-radius: 10px;
}

.color-answer {
    background-color:#2ed573;
    color: white;
}

.editButton {
    border: none;
    margin: 0px 20px;
    padding: 10px;
    border-radius: 25px;
    background-color: #2E3443;
    color: #fff;
    cursor: pointer;
    width: 90px;
    margin-top: 20px;
    margin-bottom: 20px;
    position: fixed;
    left: 90%;
    top: 50%;
    transform: translate(-50%, 0);
    font-size: 16px;
    font-weight: bold;
}

.editButton:hover{
    border: 1px solid #2E3443;
    background-color: white;
    color: black;
}

